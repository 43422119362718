import React, { useEffect } from 'react';
import "../../lib/flot/jquery.flot";
import "../../lib/flot/jquery.flot.resize";
import "jquery.flot.tooltip";
import { getCustomerGrowthReport } from "../../api/app";
import formatNumber from "../../utils/formatNumber";
const $ = window.$;

const CustomerGrowth = () => {

    useEffect(() => {
        getCustomerGrowthReport().then((response) => {
            if (!response.error) {
                // Update total and daily customer values
                $("#total-customer").text(formatNumber(response.payload.total_customer));
                $("#total-active-subscriber").text(formatNumber(response.payload.total_active_subscriber));
                $("#churn-rate").text(`${formatNumber(response.payload.churn_rate.toFixed(2))}%`);
                $("#retention-rate").text(`${formatNumber(response.payload.retention_rate.toFixed(2))}%`);

                // Map chartData to Flot's required format
                const formattedChartCustomer = response.payload.chart_customer.map((value, index) => [index, value]);
                const formattedChartSubscriber = response.payload.chart_subscriber.map((value, index) => [index, value]);

                // Months array for the x-axis
                const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                // Update the Flot chart with the new data
                // Update the Flot chart with the new data
                $.plot('#flotLine', [
                    {
                        data: formattedChartCustomer,
                        color: '#0168fa'
                    },
                    {
                        data: formattedChartSubscriber,
                        color: '#f10075'
                    }
                ], {
                    series: {
                        lines: {
                            show: true,
                            lineWidth: 2
                        },
                        shadowSize: 0
                    },
                    grid: {
                        hoverable: true,
                        clickable: true,
                        borderColor: '#ddd',
                        borderWidth: 0,
                        labelMargin: 5
                    },
                    yaxis: {
                        tickColor: '#edeff6',
                        ticks: 6,
                        font: {
                            color: '#001737',
                            size: 10
                        }
                    },
                    xaxis: {
                        ticks: months.map((month, index) => [index, month]), // Map months to ticks
                        tickColor: '#edeff6',
                        font: {
                            color: '#001737',
                            size: 10
                        },
                        alignTicksWithAxis: 1, // Aligns ticks properly
                        position: 'bottom'
                    }
                });
            }
        });
    }, []);

    return (
        <div className="col-lg-5 col-xl-5">
            <div className="card ht-md-450">
                <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                    <h6 className="mg-b-5">Customer Growth</h6>
                    <p className="tx-12 tx-color-03 mg-b-0">Number of customers who have active subscription with you.</p>
                </div>
                <div className="card-body pd-20">
                    <div className="chart-two mg-b-20">
                        <div id="flotLine" style={{height: 220}}></div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm-6">
                            <h4 className="tx-normal tx-rubik tx-spacing--1 mg-b-5" id="total-customer">0</h4>
                            <p className="tx-11 tx-uppercase tx-spacing-1 tx-semibold mg-b-10 tx-primary">Total Annual Customer</p>
                        </div>

                        <div className="col-sm-6 mg-t-20 mg-sm-t-0">
                            <h4 className="tx-normal tx-rubik tx-spacing--1 mg-b-5" id="total-active-subscriber">K0.00</h4>
                            <p className="tx-11 tx-uppercase tx-spacing-1 tx-semibold mg-b-10 tx-pink">Total Active Subscriber</p>
                        </div>

                        <div className="col-sm-6 mt-2">
                            <h4 className="tx-normal tx-rubik tx-spacing--1 mg-b-5" id="churn-rate">0%</h4>
                            <p className="tx-11 tx-uppercase tx-spacing-1 tx-semibold mg-b-10 tx-color-01">Churn Rate</p>
                        </div>

                        <div className="col-sm-6 mt-2">
                            <h4 className="tx-normal tx-rubik tx-spacing--1 mg-b-5" id="retention-rate">0%</h4>
                            <p className="tx-11 tx-uppercase tx-spacing-1 tx-semibold mg-b-10 tx-color-01">Retention Rate</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerGrowth;
