import React, {useEffect, useState} from 'react';
import {getRating} from "../../api/app";
import $ from "jquery";
import formatNumber from "../../utils/formatNumber";
import {Link} from "react-router-dom";

const Rating = () => {

    const [rating, setRating] = useState([]);
    const [average, setAverage] = useState(0);

    useEffect(() => {
        getRating().then((response) => {
            setRating(response.payload.feedbacks);
            setAverage(response.payload.averageRating);
        });
    }, []);

    const renderStars = (rate) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <i key={i} className={`icon ion-md-star lh-0 ${i <= rate ? 'tx-orange' : 'tx-gray-300'}`}></i>
            );
        }
        return stars;
    };

    return (
        <div className="col-12 col-md-4 col-lg-4 mg-t-10">
            <div className="card">
                <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                    <h6 className="lh-5 mg-b-5">Customer Rating</h6>
                    <p className="tx-12 tx-color-03 mg-b-0">Measures the quality or your support team’s efforts.</p>
                </div>

                <div className="card-body pd-0">
                    <div className="list-group list-group-flush tx-13 ht-lg-400">
                        {rating.length <= 0 ? <div className="d-flex justify-content-center my-auto tx-18">
                            <div className="text-center">
                                <span className="tx-color-04"><i data-feather="star" className="wd-60 ht-60"></i></span>
                                <h6 className="lh-5 mg-b-5 mg-t-2">No Rating</h6>
                                <p className="tx-12 tx-color-03 mg-b-0">Ratings will appear shortly after the customer
                                    submits their review.</p>
                            </div>
                        </div> : <div>
                            <div className="list-group list-group-flush tx-13">
                                {rating.length > 0 ?
                                    <div className="pd-t-10 pd-b-15 pd-x-20 d-flex align-items-baseline">
                                        <h1 className="tx-normal tx-rubik mg-b-0 mg-r-5">{average.toFixed(1)}</h1>
                                        <div className="tx-18">
                                            {renderStars(average)}
                                        </div>
                                    </div> : null}
                                {rating.slice(0, 8).map((item) => {
                                    return (
                                        <div className="list-group-item pd-y-5 pd-x-20 d-flex align-items-center">
                                            <strong className="tx-12 tx-rubik">{item.rate.toFixed(1)}</strong>
                                            <div className="tx-16 mg-l-10">
                                                {renderStars(item.rate)}
                                            </div>
                                            <div
                                                className="mg-l-auto tx-rubik tx-color-02 text-capitalize">{item.user.first_name} {item.user.last_name}</div>
                                        </div>
                                    )
                                })}
                                {rating.length > 8 ? <div className="card-footer text-center tx-13">
                                    <Link to="/rate" className="link-03">View All Rating <i
                                        className="icon ion-md-arrow-down mg-l-5"></i></Link>
                                </div> : null}
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Rating;
