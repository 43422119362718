import React, { useEffect } from 'react';
import Chart from 'chart.js';
import {getRecurRevenueReport} from "../../api/app";
import formatNumber from "../../utils/formatNumber";
const $ = window.$;

const RecurRevenue = () => {

    useEffect(() => {
        getRecurRevenueReport().then((response) => {
            if (!response.error) {
                $("#average-monthly-revenue").text(`K${formatNumber(response.payload.average_monthly_revenue.toFixed(2))}`);
                $("#average-weekly-revenue").text(`K${formatNumber(response.payload.average_weekly_revenue.toFixed(2))}`);

                updateRate("#growth-rate", response.payload.growth_rate);

                var ctx1 = document.getElementById("revenue-chart").getContext('2d');
                new Chart(ctx1, {
                    type: 'bar',
                    data: {
                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        datasets: [{
                            data: response.payload.chartData,
                            backgroundColor: '#10b759'
                        }]
                    },
                    options: {
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                            labels: {
                                display: false
                            }
                        },
                        scales: {
                            xAxes: [{
                                display: true,
                                barPercentage: 0.4
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: '#ebeef3'
                                },
                                ticks: {
                                    fontColor: '#8392a5',
                                    fontSize: 10,
                                    min: 0
                                }
                            }]
                        },
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    return new Intl.NumberFormat().format(tooltipItem.yLabel);
                                }
                            }
                        }
                    }
                });
            }
        });
    }, []);

    const updateRate = (selector, rate) => {
        const isPositive = rate > 0 || rate === 0; // Treat 0 as positive for the arrow
        const displayRate = rate === 0 ? 100 : Math.abs(rate).toFixed(2); // Display 100% if rate is 0
        const iconName = isPositive ? "arrow-up-outline" : "arrow-down-outline";
        const rateClass = rate === 0 || isPositive ? "tx-success" : "tx-danger"; // Default to success style for 0 or positive rates

        $(selector).html(`
        <h3 class="tx-normal ${rateClass} tx-rubik tx-success tx-spacing--2 mg-b-5 d-flex">
            ${displayRate}% <ion-icon name="${iconName}"></ion-icon>
        </h3>
    `);
    };

    return (
        <div className="col-lg-7 col-xl-7">
            <div className="card">
                <div className="card-header pd-y-20 d-md-flex align-items-center justify-content-between">
                    <h6 className="mg-b-0">Recurring Sale Revenue</h6>
                    <ul className="list-inline d-flex mg-t-20 mg-sm-t-10 mg-md-t-0 mg-b-0">
                        <li className="list-inline-item d-flex align-items-center">
                            <span className="d-block wd-10 ht-10 bg-df-1 rounded mg-r-5" style={{background: "#10b759"}}></span>
                            <span className="tx-sans tx-uppercase tx-10 tx-medium tx-color-03">Sale Revenue</span>
                        </li>
                    </ul>
                </div>
                <div className="card-body pd-b-4 pd-t-10" style={{height: 305}}>
                    <canvas id="revenue-chart"></canvas>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-sm-4">
                            <h3 className="tx-normal tx-rubik tx-spacing--2" id="average-monthly-revenue">K0.00</h3>
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold">AGR Monthly Revenue</h6>
                        </div>

                        <div className="col-sm-4 mg-t-20 mg-sm-t-0">
                            <h3 className="tx-normal tx-rubik tx-spacing--2 mg-b-5" id="average-weekly-revenue">K0.00</h3>
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold">AGR Weekly Revenue</h6>
                        </div>

                        <div className="col-sm-4 mg-t-20 mg-sm-t-0">
                            <h3 className="tx-normal tx-rubik tx-spacing--2 mg-b-5" id="growth-rate">0%</h3>
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold">Growth Rate</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RecurRevenue;
