import React, {useEffect, useState} from 'react';
import PieChart from "../../components/charts/PieChart";
import $ from "jquery";
import feather from "feather-icons";
import {getRevenueReport} from "../../api/app";
import formatNumber from "../../utils/formatNumber";
import Rating from "../../components/common/Rating";
import Revenue from "../../components/analytic/Revenue";
import Collection from "../../components/charts/Revenue";
import SaleRevenue from "../../components/analytic/SaleRevenue";
import RecurRevenue from "../../components/analytic/AnnualRevenue";
import CustomerGrowth from "../../components/analytic/CustomerGrowth";

const Overview = () => {

    const [revenueData, setRevenueData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rating, setRating] = useState(null);

    $(function () {
        'use strict';
        feather.replace();
    });

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">

                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Sales Monitoring</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Welcome to Dashboard</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <SaleRevenue/>
                    <RecurRevenue/>
                    <CustomerGrowth/>
                    <Rating/>
                    <Revenue/>

                    <div className="col-lg-8 col-xl-9">
                        <div className="card">
                            <div
                                className="card-header bd-b-0 pd-t-20 pd-lg-t-25 pd-l-20 pd-lg-l-25 d-flex flex-column flex-sm-row align-items-sm-start justify-content-sm-between">
                                <div>
                                    <h6 className="mg-b-5">Garbage Collection Metrics</h6>
                                    <p className="tx-12 tx-color-03 mg-b-0">Audience to which the users belonged while on the current date range.</p>
                                </div>
                                <div className="btn-group mg-t-20 mg-sm-t-0">
                                    <button className="btn btn-xs btn-white btn-uppercase">Monthly</button>
                                </div>
                            </div>
                            <div className="card-body pd-lg-25">
                                <div className="row align-items-sm-end">
                                    <div className="col-lg-7 col-xl-8">
                                        <Collection id="chartBar2"/>
                                    </div>
                                    <div className="col-lg-5 col-xl-4 mg-t-30 mg-lg-t-0">
                                        <div className="row">
                                            <div className="col-sm-6 col-lg-12">
                                                <div
                                                    className="d-flex align-items-center justify-content-between mg-b-5">
                                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Collections</h6>
                                                    <span className="tx-10 tx-color-04">0% goal reached</span>
                                                </div>
                                                <div className="d-flex align-items-end justify-content-between mg-b-5">
                                                    <h5 className="tx-normal tx-rubik lh-2 mg-b-0">0</h5>
                                                    <h6 className="tx-normal tx-rubik tx-color-03 lh-2 mg-b-0">0</h6>
                                                </div>
                                                <div className="progress ht-4 mg-b-0 op-5">
                                                    <div className="progress-bar bg-teal wd-0p" role="progressbar"
                                                         aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-12 mg-t-30 mg-sm-t-0 mg-lg-t-30">
                                                <div
                                                    className="d-flex align-items-center justify-content-between mg-b-5">
                                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Collected</h6>
                                                    <span className="tx-10 tx-color-04">0% goal reached</span>
                                                </div>
                                                <div className="d-flex justify-content-between mg-b-5">
                                                    <h5 className="tx-normal tx-rubik mg-b-0">0</h5>
                                                    <h5 className="tx-normal tx-rubik tx-color-03 mg-b-0">
                                                        <small>0</small></h5>
                                                </div>
                                                <div className="progress ht-4 mg-b-0 op-5">
                                                    <div className="progress-bar bg-orange wd-0p" role="progressbar"
                                                         aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-12 mg-t-30">
                                                <div
                                                    className="d-flex align-items-center justify-content-between mg-b-5">
                                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Uncollected</h6>
                                                    <span className="tx-10 tx-color-04">0% goal reached</span>
                                                </div>
                                                <div className="d-flex justify-content-between mg-b-5">
                                                    <h5 className="tx-normal tx-rubik mg-b-0">0</h5>
                                                    <h5 className="tx-normal tx-rubik tx-color-03 mg-b-0">
                                                        <small>0</small></h5>
                                                </div>
                                                <div className="progress ht-4 mg-b-0 op-5">
                                                    <div className="progress-bar bg-pink wd-0p" role="progressbar"
                                                         aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-12 mg-t-30">
                                                <div
                                                    className="d-flex align-items-center justify-content-between mg-b-5">
                                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-semibold mg-b-0">Collection
                                                        Rate</h6>
                                                    <span className="tx-10 tx-color-04">0% goal reached</span>
                                                </div>
                                                <div className="d-flex justify-content-between mg-b-5">
                                                    <h5 className="tx-normal tx-rubik mg-b-0">0%</h5>
                                                    <h5 className="tx-normal tx-rubik tx-color-03 mg-b-0">
                                                        <small>0%</small></h5>
                                                </div>
                                                <div className="progress ht-4 mg-b-0 op-5">
                                                    <div className="progress-bar bg-primary wd-0p" role="progressbar"
                                                         aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 mg-t-10 mg-lg-t-10">
                        <div className="card">
                            <div className="card-header">
                                <h6 className="mg-b-0">Dumpsite Frequency Metric</h6>
                            </div>
                            <div className="card-body pd-lg-25">
                                <PieChart/>
                            </div>
                            <div className="card-footer pd-20">
                                <div className="row">
                                    <div className="col-6">
                                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-5">Yearly</p>
                                        <div className="d-flex align-items-center">
                                            <div className="wd-10 ht-10 rounded-circle bg-pink mg-r-5"></div>
                                            <h6 className="tx-normal tx-rubik mg-b-0">0</h6>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">Monthly</p>
                                        <div className="d-flex align-items-center">
                                            <div className="wd-10 ht-10 rounded-circle bg-primary mg-r-5"></div>
                                            <h6 className="tx-normal tx-rubik mg-b-0">0</h6>
                                        </div>
                                    </div>
                                    <div className="col-6 mg-t-20">
                                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">Weekly</p>
                                        <div className="d-flex align-items-center">
                                            <div className="wd-10 ht-10 rounded-circle bg-teal mg-r-5"></div>
                                            <h6 className="tx-normal tx-rubik mg-b-0">0</h6>
                                        </div>
                                    </div>
                                    <div className="col-6 mg-t-20">
                                        <p className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">Daily</p>
                                        <div className="d-flex align-items-center">
                                            <div className="wd-10 ht-10 rounded-circle bg-orange mg-r-5"></div>
                                            <h6 className="tx-normal tx-rubik mg-b-0">0</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;
